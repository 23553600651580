import React from 'react';
import { NavLink } from 'react-router-dom';
import { routeNames} from 'routes';

const Navbar = () => {


  return (
      <div className='topMenu'>
        <div className='menuContent'>
          <NavLink to={routeNames.home} className={({ isActive }) =>isActive ? 'activeMenuBtn' : 'menu-btn'}>HOME</NavLink>
          <NavLink to={routeNames.ar} className={({ isActive }) =>isActive ? 'activeMenuBtn' : 'menu-btn'}>AR CONNECT</NavLink>
          <NavLink to={routeNames.rarity} className={({ isActive }) =>isActive ? 'activeMenuBtn' : 'menu-btn'}>RARITY</NavLink>
          <a href='https://whitepaper.dragonsarena.io/' className='whitepaper-btn' target='_blank' rel='noreferrer'>WHITEPAPER</a>
          <NavLink to={routeNames.account} className={({ isActive }) =>isActive ? 'activeMenuBtn' : 'menu-btn'}>ACCOUNT</NavLink>
        </div>
        <div id="menuLabel"></div>

        <div className='socials'>
          <a href='https://twitter.com/DragonsArena_io' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/twitter.png`}/></a>
          <a href='https://discord.gg/dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/discord.png`}/></a>
          <a href='https://instagram.com/dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/instagram.png`}/></a>
          <a href='https://t.me/dragonsarena_io' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/telegram.png`}/></a>
          <a href='https://www.tiktok.com/@dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/tiktok.png`}/></a>
        </div>
      </div>
  );
};

export default Navbar;
