import React from 'react';
import {rar} from './Assets/rar';

const Rarity = () => {

    const [searchTerm,setSearchTerm] = React.useState('');
    const [mapView, setMapView] = React.useState<number>(100);

    const pageTrottle = 50;

    const handleScroll = () => {
        if(mapView > 5900)
        {
            return false;
        }
        else
        {
            if(searchTerm == '')
            {
                const bottom = Math.ceil(window.innerHeight + window.scrollY + pageTrottle) >= document.documentElement.scrollHeight;
                if (bottom) 
                {
                  setMapView(mapView + 100);
                }
            }
        }
      };
      React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
          passive: true
        });
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [mapView,searchTerm]);

    return(
            <div className='rarityContent'>
            <h2 className='fjalla'>RARITY</h2>
            <div id='pageLabel'></div>
                <div className='searchBox'>
                    <div className='searchText fjalla'>Search by #ID</div>
                    <input className="search" type="text" placeholder="Dragons Arena #1" onChange={e=>setSearchTerm(e.target.value)} />
                </div>
                <div className='disclaimer'>Please take in consideration that the rarity can differ from the marketplaces!</div>
                <div className='rarityResults'>
                    {
                    rar.filter((val:any)=>
                    {
                        if(searchTerm == ''){
                            return val;
                        }
                        else
                        {
                            if(val.id == searchTerm || '#'+val.id == searchTerm || 'Dragons Arena #'+val.id == searchTerm || 'DragonsArena #'+val.id == searchTerm)
                            {
                                return val;
                            }
                        }
                    }).map((val,key)=>{
                        // if(key < mapView && searchTerm != '')
                        if(key < mapView)
                        {
                            return (
                                <div className='rarityContainer' key={key}>
                                    <div className='rarityImg'>
                                    <img src={val.image}/>
                                    </div>
                                    <div className='rarityDetails'>
                                        {val.name}
                                        <div className='itemRank'>Rank #{val.rank}</div>
                                        <div className='itemRank'>Score {Math.round(val.rarity)}</div>
                                    </div>
                                    <div className='attributes'>
                                    {
                                        val.attributes.map((trait: any, index: number) => (
                                            String(val.id) == searchTerm || '#'+val.id == searchTerm || 'Dragons Arena #'+val.id == searchTerm || 'DragonsArena #'+val.id == searchTerm?
                                            (
                                            <div className='rarities' key={index}>
                                                <div className='traitType'>
                                                    {trait.trait_type}
                                                </div>
                                                <div className='traitValue'>
                                                    {trait.value}
                                                </div>
                                            </div>   
                                            )
                                            :
                                            (
                                            ''
                                            )
                                        ))
                                    }
                                    </div>
                                </div>
                            );
                        }
                    })
                    }
                </div>
            </div>
    );
};
export default Rarity;