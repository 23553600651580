import * as React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import axios from 'axios';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Actions = () =>
{
    const [rewardsMap, setRewardsMap] = React.useState([]);
    const [whoClaimed, setWhoClaimed] = React.useState<boolean>(false);
    const [rewardRow, setRewardRow] = React.useState();
    const [poolSizeMap, setPoolSizeMap] = React.useState([]);
    const [isSlideshowActive, setIsSlideshowActive] = React.useState<boolean>(false);
    const [dailyActiveHolders, setDailyActiveHolders] = React.useState();
    const [weeklyActiveHolders, setWeeklyActiveHolders] = React.useState();
    const [totalClaimed, setTotalClaimed] = React.useState();
    const [totalLKMEX, setTotalLKMEX] = React.useState<number>(1);

    const source = axios.CancelToken.source();
    const mounted = React.useRef(true);
    const account = useGetAccountInfo();
    const { address } = account;

const fetchRewards = () => {
    axios.get('https://ar.dragonsarena.io/v2/dapp/dappArRewardsV2.php', {cancelToken: source.token})
    .then((response) => {
        const rewards = Object.values(response['data']['rewards']) as any;
        setRewardsMap(rewards);
    })
    .catch((error) => {
        console.log(error);
    });
};

const fetchPoolSize = () => {
    axios.get('https://ar.dragonsarena.io/dappPoolSize.php', {cancelToken: source.token})
    .then((response) => {
        const poolSize = Object.values(response['data']['pool']) as any;
        setPoolSizeMap(poolSize);
        setIsSlideshowActive(true);
    })
    .catch((error) => {
        console.log(error);
    });
};

const dappStats = () => {
    axios.get('https://ar.dragonsarena.io/v2/dapp/dappRaffleStats.php', {cancelToken: source.token})
    .then((response) => {
        const stats = Object.values(response['data']['dappStats']) as any;
        setDailyActiveHolders(stats[0]['dailyActiveHolders']);
        setWeeklyActiveHolders(stats[1]['weeklyActiveHolders']);
        setTotalClaimed(stats[2]['totalClaimed']);
        setTotalLKMEX(stats[3]['totalLKMex']);
    })
    .catch((error) => {
        console.log(error);
    });
};

const markPending = (id:any) => {
    axios.post('https://ar.dragonsarena.io/dappMarkPending.php', {id:id})
    .then(() =>
    {
        window.location.reload();
    })
    .catch((error) => 
    {
        console.log(error);
    });
};



const whoClaimedReveal = (index:any) =>
{
    if(whoClaimed && index == rewardRow)
    {
        setWhoClaimed(false);
    }
    else
    {
        setWhoClaimed(true);
        setRewardRow(index);
    }

};

React.useEffect(() => {
    fetchRewards();
    fetchPoolSize();
    dappStats();
    return () => {
        mounted.current = false;
        source.cancel();
    };
}, []);

//Infinite pagination
const [mapView, setMapView] = React.useState<number>(10);
const pageTrottle = 150;
const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY + pageTrottle) >= document.documentElement.scrollHeight;
    if (bottom) 
    {
        setMapView(mapView + 10);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [mapView]);

  const items = poolSizeMap.map((item:any, index) => {
      const style = { width: 160 + index * 100 };
      return (
            <div className='slideItem' key={index} style={style}>
                <div><img src={'https://dragonsarena.io/img/pool/'+item.image+'.png'}></img></div>
                <div className='slideProjectName'>{item.name}</div>
                <div className='slideProjectSocials'>
                    {item.twitter ? (<a href={item.twitter} target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/twitter.png`}/></a>):('')}
                    {item.discord ? (<a href={item.discord} target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/discord.png`}/></a>):('')}
                     {item.website ? (<a href={item.website} target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/website.png`}/></a>):('')}
                </div>
        </div>
      );
  });

return(
    <div className='section sectionARConnect'>
        <h2 className='fjalla'>AR CONNECT</h2>
            <div id='pageLabel'></div>
            <div className='downloadARBox'>
                <a href='https://play.google.com/store/apps/details?id=com.DragonsArenaAR' target='_blank' rel="noreferrer" className='googlePlay'></a>
                <a href='https://apps.apple.com/us/app/dragons-ar-connect/id1621731320' target='_blank' rel="noreferrer" className='appStore'></a>
            </div>

        <div className='arStatsBox'>
        <div className='arstats'>
            <div className='statsBox'>
                <div className='stat statsDaily'><div className='dailyActive'></div>{dailyActiveHolders}</div>
                <div>DAILY ACTIVE</div>
            </div>
            <div className='statsBox'>
                <div className='stat statsWeekly'><div className='weeklyIco'></div>{weeklyActiveHolders}</div>
                <div>WEEKLY ACTIVE</div>
            </div>
            <div className='statsBox'>
                <div className='stat statsTotalClaimed'><div className='claimedIco'></div>{totalClaimed}</div>
                <div>TOTAL CLAIMED</div>
            </div>
            {/* <div className='statsBox'>
                <div className='stat statsTotalLKMex'><div className='lkmexIco'></div>{totalLKMEX.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
                <div>LKMEX DISTRIBUTED</div>
            </div> */}
        </div>
        </div>
        <h2 className='fjalla'>POOLS</h2>
        <div id='pageLabel'></div>
        <div className='poolContainer'>
            <div className='poolContainerBox'>
                        <div className='rewardsLegend'>
                            <div className = 'poolLegend'>
                                    PRIZE
                            </div>
                            <div className = 'poolLegend'>
                                    POOL SIZE
                            </div>
                            <div className = 'poolLegend'>
                                    REWARD/BOX
                            </div>
                        </div>
                        {
                            poolSizeMap.map((pool:any, index:number) =>
                            {
                                if(pool.quantity > 0)
                                {
                                    return(
                                    <div key={index}>
                                        <div className='rewardPoolContainer'>
                                            <div className = 'poolLegend poolName'>
                                                    {pool.name}
                                            </div>
                                            <div className = 'poolLegend'>
                                                    {pool.quantity}
                                            </div>
                                            <div className = 'poolLegend'>
                                                {
                                                pool.name == 'LKMEX' ?
                                                (
                                                    <div> 5,000 - 500,000</div>
                                                )
                                                :
                                                (
                                                pool.prize
                                                )
                                                }
                                            </div>
                                        </div>                             
                                    </div>
                                    );
                                }
                            }
                            )
                        }
            </div>
        </div>
        <div className='arSlideshow'>
            {
                isSlideshowActive ?
                (
                    <AliceCarousel
                    autoWidth
                    autoPlay
                    infinite
                    mouseTracking
                    disableDotsControls
                    disableButtonsControls
                    items={items}
                    autoPlayInterval={1500}
                     />
                ):
                (
                    ''
                )
            }
        </div>
    <h2 className='fjalla'>REWARDS BOARD</h2>
    <div id='pageLabel'></div>

    <div className='rewardsContainer'>
        <div className='rewardsContainerOverflow'>
                    <div className='rewardsLegend'>
                        <div className = 'rewardColumn rewardId rewardLegendColumn'>
                                ID
                        </div>
                        <div className = 'rewardColumn rewardDragonId rewardLegendColumn'>
                                DRAGON ID
                        </div>
                        <div className = 'rewardColumn rewardType rewardLegendColumn'>
                                TYPE
                        </div>
                        <div className = 'rewardColumn rewardPrizeName rewardLegendColumn'>
                                PRIZE
                        </div>
                        <div className = 'rewardColumn rewardQuantity rewardLegendColumn'>
                                QUANTITY
                        </div>
                        <div className = 'rewardColumn startDate rewardLegendColumn'>
                               DATE
                        </div>
                        <div className = 'rewardColumn rewardStatus rewardLegendColumn'>
                               STATUS
                        </div>
                    </div> 
       {
            rewardsMap.map((reward: any, index: number) =>
                {
                    if(index < mapView)
                    {
                        return(
                            <div key={index}>
                            {
                                 <div>
                                 <div className='rewardsRow'>
                                         <div className = 'rewardColumn rewardId'>
                                             {reward.id}
                                     </div>
                                     <div className = 'rewardColumn rewardDragonId'>
                                         {
                                             reward.claimStatus == 'PENDING' ?
                                             (
                                                'Dragons Arena #****'
                                             ):
                                             (
                                                reward.dragonid.replace('DragonsArena','Dragons Arena')
                                             )
                                         }
                                     </div>
                                     <div className = 'rewardColumn rewardType'>
                                         {
                                             (() => {
                                                if (reward.dragontype == 'Epic')
                                                {
                                                    return(
                                                        <div className='epicTypePrize'>EPIC</div>
                                                     );
                                                }
                                                else if (reward.dragontype == 'Legendary')
                                                {
                                                    return(
                                                        <div className='legendaryTypePrize'>LEGENDARY</div>
                                                     );
                                                }
                                                else
                                                {
                                                    return(
                                                        <div className='commonTypePrize'>COMMON</div>
                                                     );                 
                                                }
                                             })()
    
                                        }
                                     </div>
                                     <div className = 'rewardColumn rewardPrizeName'>
                                             {reward.prizeName}
                                     </div>
                                     <div className = 'rewardColumn rewardQuantity'>
                                             {reward.prize}
                                     </div>
                                     <div className = 'rewardColumn startDate'>
                                             <span className='date'>{reward.submitDate}</span>
                                     </div>
                                     <div className='rewardColumn rewardStatus'>
                                     {
                                         reward.claimed == 1 ?
                                         (
                                                 reward.statusReward == 'Pending' && address == 'erd1hcgv2gtr724fxsw2gz2wajydxql8ykfccr5m0gpkfhydxlmv6lrquz2w7u' ?
                                                 (
                                                    <button className='rewardClaimedPending' onClick={() => whoClaimedReveal(index)}>
                                                        PENDING
                                                    </button>                                                  
                                                 )
                                                 :
                                                 (
                                                    <button className='rewardClaimed' onClick={() => whoClaimedReveal(index)}>
                                                        CLAIMED
                                                    </button>
                                                 )
                                         )
                                         :
                                         (
                                             reward.claimStatus == 'PENDING' ?
                                             (
                                                <div className='rewardPendingClaim'>
                                                    PENDING
                                                </div>
                                             ):
                                             (
                                                <div className='rewardUnClaimed'>
                                                    NOT CLAIMED
                                                </div>
                                             )
                                         )
                                     }
                                     </div>
                                 </div>
                                 {
                                     whoClaimed && index == rewardRow?
                                     (
                                         <div className='whoClaimed'>
                                             <div className='whoClaimedRow'>
                                                 <div className='whoClaimedLabel'>Wallet</div>
                                                 <div className='whoClaimedWallet'>{reward.wallet}</div>
                                             </div>
                                             <div className='whoClaimedRow'>
                                                 <div className='whoClaimedLabel'>Date</div>
                                                 <div className='whoClaimedDate'>{reward.claimDate}</div>
                                             </div>
                                             <div className='whoClaimedRow'>
                                                 <div className='whoClaimedLabel'>Status</div>
                                                 <div className='whoClaimedDate'>{reward.statusReward}</div>
                                             </div>
                                             {
                                             reward.statusReward == 'Pending' && address == 'erd1hcgv2gtr724fxsw2gz2wajydxql8ykfccr5m0gpkfhydxlmv6lrquz2w7u' ?
                                                 (
                                                    <button className='markPending' onClick={() => markPending(reward.id)}>
                                                        MARK
                                                    </button>                                                  
                                                 ):
                                                 (
                                                     ''
                                                 )
                                            }
                                         </div>
                                         
                                     )
                                     :
                                     (
                                         ''
                                     )
                                 }
                                </div>
                            }
                             </div>
                         );
                    }
                    
                }
            )
       }
    </div>
    </div>
    </div>
);
};

export default Actions;
