import { dAppName } from 'config';
import withPageTitle from './components/PageTitle';
import Account from './pages/Account';
import Ar from './pages/Ar';
import Home from './pages/Home';
import Rarity from './pages/Rarity';
import Transaction from './pages/Transaction';
import Connect from './pages/UnlockPage';

export const routeNames = {
  home: '/',
  mint: '/mint',
  ar: '/ar',
  account: '/account',
  rarity: '/rarity',
  analytics: '/analytics',
  transaction: '/transaction',
  unlock: '/unlock',
  connect: '/connect',
  ledger: '/ledger',
  walletconnect: '/walletconnect'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'MOBA PTE GAME',
    component: Home
  },
  {
    path: routeNames.ar,
    title: 'Ar',
    component: Ar
  },
  {
    path: routeNames.account,
    title: 'Account',
    component: Account,
    authenticatedRoute: true
  },
  {
    path: routeNames.rarity,
    title: 'Rarity',
    component: Rarity
  },
  {
    path: routeNames.transaction,
    title: 'Transaction',
    component: Transaction
  }
  ,
  {
    path: routeNames.connect,
    title: 'Connect',
    component: Connect
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${dAppName} - ${route.title}`
    : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
