import React from 'react';
import { NavLink } from 'react-router-dom';
import { routeNames} from 'routes';

const Footer = () => {
  return (
    <div className='footer'>
      <div id="footerLabel"></div>
        <div className='footerContent'>
          <div className='footerMenu'>
              <NavLink to={routeNames.home} className={({ isActive }) =>isActive ? 'activeFooterBtn' : 'footer-btn'}>HOME</NavLink>
              <NavLink to={routeNames.ar} className={({ isActive }) =>isActive ? 'activeFooterBtn' : 'footer-btn'}>AR CONNECT</NavLink>
              <NavLink to={routeNames.rarity} className={({ isActive }) =>isActive ? 'activeFooterBtn' : 'footer-btn'}>RARITY</NavLink>
              <a href='https://whitepaper.dragonsarena.io/' className='footer-btn' target='_blank' rel='noreferrer'>WHITEPAPER</a>
              <NavLink to={routeNames.account} className={({ isActive }) =>isActive ? 'activeFooterBtn' : 'footer-btn'}>ACCOUNT</NavLink>
          </div>
        </div>
        <div className='socials'>
          <a href='https://twitter.com/DragonsArena_io' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/twitter.png`}/></a>
          <a href='https://discord.gg/dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/discord.png`}/></a>
          <a href='https://instagram.com/dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/instagram.png`}/></a>
          <a href='https://t.me/dragonsarena_io' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/telegram.png`}/></a>
          <a href='https://www.tiktok.com/@dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/tiktok.png`}/></a>
        </div>
        <div className='copyright'>
          © Dragons Arena 2022
        </div>
        <div className='egldcommunity'>
          <iframe src='https://egld.community/api/products/26cca753-303e-4364-b64c-0b044dbc373b/upvotes/embed' width='290' height='70' style={{borderRadius: '8px', border: 'none', width: '290px', height: '70px'}}></iframe>
        </div>
    </div>
  );
};

export default Footer;
