import React from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { routeNames} from 'routes';


export const Home: () => JSX.Element = () => {

  const [rewardsMap, setRewardsMap] = React.useState([]);
  const [faqAnswer, setFaqAnswer] = React.useState<boolean>(false);
  const [faqAnswerId, setFaqAnswerId] = React.useState();
  const source = axios.CancelToken.source();
  const mounted = React.useRef(true);

  const fetchRewards = () => {
    axios.get('https://ar.dragonsarena.io/v2/dapp/dappARClaimedRewards.php', {cancelToken: source.token})
    .then((response) => {
        const rewards = Object.values(response['data']['rewards']) as any;
        setRewardsMap(rewards);
    })
    .catch((error) => {
        console.log(error);
    });
};

const faq = (q:any) =>
{
  const currentFaqId = faqAnswerId;
  if(currentFaqId == q)
  {
    if(faqAnswer == true)
    {
      setFaqAnswer(false);
    }
    else
    {
      setFaqAnswer(true);
      setFaqAnswerId(q);
    }
  }
  else
  {
    setFaqAnswer(true);
    setFaqAnswerId(q);
  }

};

React.useEffect(() => {
  fetchRewards();
  return () => {
      mounted.current = false;
      source.cancel();
  };
}, []);
  return (
    <div>
      {/* <div className='gameVideo'>
        <video muted autoPlay loop className='game-teaser'>
          // <source src={`${process.env.PUBLIC_URL}/teaser/TheDragonValleyTeaser.mp4#t=3`} type="video/mp4"/>
        </video>
        <div id='videOverlay'></div>

      </div> */}
      <section className='section sectionAbout'>
        <div className='brand'>
          <img src={`${process.env.PUBLIC_URL}/img/DragonsArenaLogo.png`}></img>
        </div>
        <div className='aboutContent'>
          <div className='about'>
            <div className='aboutRow'>
              <div className="diamond"></div><div> Dragons Arena is a MOBA PTE Game based on MultiversX blockchain technology where players compete in battles and earn $EGODS token.</div>
            </div>
            <div className='aboutRow'>
              <div className="diamond"></div><div> $EGODS is designed to be the main utility token of Dragons Arena.</div>
            </div>
            <div className='aboutRow'>
              <div className="diamond"></div><div> Reveal in-game characters with Dragons Arena NFTS.</div>
            </div>
          </div>
          <div id='pageLabel'></div>
        </div>
      </section>
      {/* <section className='section sectionGame'>
        <h2 className='fjalla'>PLAY TO EARN GAME</h2>
        <div id='pageLabel'></div>
        <div className='gameContent'>
          <video width="750" height="500" controls >
              <source src="./Videos/video1.mp4" type="video/mp4"/>
        </video>
        </div>
      </section> */}

      <section className='section sectionAR'>
        <h2 className='fjalla'>AUGMENTED REALITY</h2>
        <div id='pageLabel'></div>
        <div className='arContent'>
          <div className='arBox'>
              <div>
                <div>
                  <div className='arLabels'>
                    <div className='arAbout'>
                      <div className='diamondBig'></div>
                      <div>TRANSPOSE</div>
                    </div>
                    <div className='arAboutLabel'>
                      Transpose Dragons Arena NFTS beyond the flat screen of your smart device into the real world.
                    </div>
                  </div>
                  <div className='arLabels'>
                  <div className='arAbout'>
                      <div className='diamondBig'></div>
                      <div>WIN</div>
                    </div>
                    <div className='arAboutLabel'>
                      Every 4 hours a box with random prizes can land close to your Dragon.
                    </div>
                  </div>
                  <div className='arLabels'>
                  <div className='arAbout'>
                      <div className='diamondBig'></div>
                      <div>CLAIM</div>
                    </div>
                    <div className='arAboutLabel'>
                      Open the box and claim the reward.
                    </div>
                  </div>
                  <div className='arLabels'>
                  <div className='arAbout'>
                      <div className='diamondBig'></div>
                      <div>AR CONNECT PROGRAM</div>
                    </div>
                    <div className='arAboutLabel'>
                      MultiversX projects can join AR CONNECT program.
                    </div>
                  </div>
                </div>
                <div className='arLegend'>
                  <a href='https://forms.gle/SGfR9VAAcxgiPthL7' target='_blank' rel='noreferrer' className='btnBorder'>JOIN AR PROGRAM<div className='arrow'></div></a>
                  <NavLink to={routeNames.ar} className='btnBorder'>PRIZE POOL <div className='arrow'></div></NavLink>
                  <NavLink to={routeNames.ar} className='btnBorder'>REWARDS <div className='arrow'></div></NavLink>
                </div>
              </div>
              <div className='arClaimedContent'>
                <div className='latestArClaimed fjalla'>
                  LATEST CLAIMED REWARDS
                </div>
                {
                  rewardsMap.map((reward: any, index: number) =>
                    (
                        <div key={index} className='arRewardsRow'>
                            <div className ='arRewardColumnDragondId'>
                              {reward.dragonid.replace('DragonsArena','Dragons Arena')}
                            </div>
                            <div className ='arRewardColumnPrizeName'>
                                    {reward.prizeName}
                            </div>
                            <div className ='arRewardColumnDate'>
                                    <span className='date'>{reward.claimDate}</span>
                            </div>
                        </div>
                      )
                    )
                  }
              </div>
          </div>
          <div className='arShots'>
            <div id="armodel"></div>
            <div id="arbox"></div>
          </div>
        </div>
        <div className='downloadARConnect'>
          <h2 className='fjalla'>DOWNLOAD AR CONNECT</h2>
          <div className='downloadARBox'>
            <a href='https://play.google.com/store/apps/details?id=com.DragonsArenaAR' target='_blank' rel="noreferrer" className='googlePlay'></a>
            <a href='https://apps.apple.com/us/app/dragons-ar-connect/id1621731320' target='_blank' rel="noreferrer" className='appStore'></a>
          </div>
        </div>
      </section>

      <section className='section sectionRoadmap'>
        <h2 className='fjalla'>ROADMAP</h2>
        <div id='pageLabel'></div>
        <div className='roadmapContent'>
            <div id="roadmapLabel"><div id="roadmapCompleted"></div></div>
              <div className='roadmap fjalla'>
                <div className='roadmapPhase'>
                    <div id="completedPhase"></div>
                      <h3>PHASE 1</h3>
                    <div className='phase'>
                    <div id="completedPhase"></div>
                      Website Creation V1
                    </div>
                    <div className='phase'>
                    <div id="completedPhase"></div>
                      Launch Social Media
                    </div>
                    <div className='phase'>
                    <div id="completedPhase"></div>
                      First Pre-Sale
                    </div>
                    <div className='phase'>
                    <div id="completedPhase"></div>
                      Second Pre-Sale
                    </div>
                    <div className='phase'>
                    <div id="completedPhase"></div>
                      Public Sale (1ST Batch)
                    </div>
                </div>
                <div className='roadmapPhase'>
                    <div id="completedPhase"></div>
                    <h3>PHASE 2</h3>
                    <div className='phase completedPhase'>
                      <div id="completedPhase"></div>
                      Augmented Reality Beta Version (Dragons AR Connect)
                    </div>
                    <div className='phase completedPhase'>
                      <div id="completedPhase"></div>
                      AR Connect Rewards System
                    </div>
                    <div className='phase completedPhase'>
                      <div id="completedPhase"></div>
                      Website Creation V2
                    </div>
                    <div className='phase completedPhase'>
                    <div id="completedPhase"></div>
                      Whitepaper and tokenomics
                    </div>
                    <div className='phase completedPhase'>
                    <div id="completedPhase"></div>
                      Public Sale (2ND Batch)
                    </div>
                    <div className='phase uncompletedPhase'>
                    <div id="currentPhase"></div>
                      Token $EGODS Launch
                    </div>
                    <div className='phase uncompletedPhase'>
                      Community Airdrop
                    </div>
                </div>
                <div className='roadmapPhase'>
                    <h3>PHASE 3</h3>
                    <div className='phase uncompletedPhase'>
                      Game Beta Version
                    </div>
                </div>
                    <div className='roadmapPhase'>
                    <h3>PHASE 4</h3>
                    <div className='phase uncompletedPhase'>
                      Game launch
                    </div>
                </div>
              </div>
        </div>
      </section>

      <section className='section sectionFaq'>
        <h2 className='fjalla'>FAQ</h2>
        <div id='pageLabel'></div>
        <div className='faqContent'>
                  <div className='faqQuestion' onClick={() => faq(1)}>
                    What is Dragons Arena?
                  </div>
                  {
                    faqAnswer == true && faqAnswerId == 1 ?
                  (
                    <div className='faqAnswer'>
                    <span>Dragons Arena NFTs is a collection of 5,888 unique 3D Dragons with high-quality graphics.</span>

                    <span>Dragons Arena Game will use the technology called Blockchain to reward players for their engagement.</span>
                    </div>
                    ):
                    (
                      ''
                    )            
                  }

                  <div className='faqQuestion' onClick={() => faq(2)}>
                      What&apos;s special about Dragons?
                  </div>
                  {
                  faqAnswer == true && faqAnswerId == 2 ?
                  (
                  <div className='faqAnswer'>
                      <span>We didn&apos;t stop at just an avatar picture. Besides the fact your Dragon Avatar will stand out because of the smallest and realistic details, you can enjoy the AR experience by transposing your NFTs beyond the flat screen of your phone into your environment.</span>

                      <span>Dragons Arena NFT holders will have the opportunity to open for a limited time a box which will reveal the in-game character.</span>

                      <span>Dragons Arena provides real ownership over in-game items by turning them into tokens/NFTs. Players are the true owners of their digital items and will have the right to trade, sell, gift their items or use them in game.</span>
                      <span>Holders of our NFTs will have early access to the Alpha version of Dragons Arena game and will have other benefits to reward their involvement in project.</span>
                  </div>
                  ):('')
                  }
                  <div className='faqQuestion' onClick={() => faq(3)}>
                    How do I get an Dragon?
                  </div>
                  {
                    faqAnswer == true && faqAnswerId == 3 ?
                  (
                  <div className='faqAnswer'>
                    <span>You can buy Dragons from <a href="https://deadrare.io/collection/DRG-875e1a" className='faqLinks' target="_blank" rel="noreferrer">Deadrare.io</a> and <a href="https://www.trust.market/collection/DRG-875e1a" className='faqLinks'  target="_blank" rel="noreferrer">Trust.market</a> or from our website when we&apos;ll re-open the next batch.</span>
                  </div>
                  ):('')
                  }
                  <div className='faqQuestion' onClick={() => faq(4)}>
                      Where can I download AR Connect?
                  </div>
                  {
                    faqAnswer == true && faqAnswerId == 4 ?
                  (
                  <div className='faqAnswer'>
                    <span>If you own a Dragons Arena NFT, you can download the application from:</span>
                    <span>Android: <a href="https://play.google.com/store/apps/details?id=com.DragonsArenaAR" className='faqLinks' target="_blank" rel="noreferrer">Google Play</a></span>
                    <span>iOS: <a href="https://apps.apple.com/us/app/dragons-ar-connect/id1621731320" className='faqLinks' target="_blank" rel="noreferrer">App Store</a></span>
                  </div>
                  ):('')
                  }
                  <div className='faqQuestion' onClick={() => faq(5)}>
                      On which blockchain is the Dragons Arena project developed?
                  </div>
                  {
                    faqAnswer == true && faqAnswerId == 5 ?
                  (
                  <div className='faqAnswer'>
                    <span>The project is developed on the MultiversX Blockchain.</span>
                  </div>
                  ):('')
                  }
                  <div className='faqQuestion' onClick={() => faq(6)}>
                    Where can I interact with & meet other community members?
                  </div>
                  {
                    faqAnswer == true && faqAnswerId == 6 ?
                  (
                  <div className='faqAnswer'>
                    <span>You can join our social pages where you can meet the rest of the community!</span>
                    <div className='socials'>
                    <a href='https://twitter.com/DragonsArena_io' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/twitter.png`}/></a>
                    <a href='https://discord.gg/dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/discord.png`}/></a>
                    <a href='https://instagram.com/dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/instagram.png`}/></a>
                    <a href='https://t.me/dragonsarena_io' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/telegram.png`}/></a>
                    <a href='https://www.tiktok.com/@dragonsarena' target='_blank' rel='noreferrer'><img src={`${process.env.PUBLIC_URL}/img/tiktok.png`}/></a>
                  </div>
                  </div>
                  ):('')
                  }
                  <div className='faqQuestion' onClick={() => faq(7)}>
                    I need help!
                  </div>
                  {
                    faqAnswer == true && faqAnswerId == 7 ?
                  (
                  <div className='faqAnswer'>
                    <span>If you encounter difficulties, please join our <a href='https://discord.gg/dragonsarena' target='_blank' className='faqLinks' rel='noreferrer'>Discord</a> server and our community will be happy to help.</span>
                  </div>
                  ):('')
                  }
        </div>
      </section>
      
      <section className='section sectionTeam'>
        <h2 className='fjalla'>TEAM</h2>
        <div id='pageLabel'></div>
        <div className='teamContent'>
                <div className='teamBox'>
                  <div className='teamCover'>
                      <img src={`${process.env.PUBLIC_URL}/img/team/1.png`}></img>
                  </div>
                  <div className='teamName'><span className='diamond'></span> El Dragón</div>
                  <div className='teamPosition'>CEO</div>
                  <div className='teamDesc'>
                    <span>• Developer</span>
                    <span>• Involved in all key decisions</span>
                    <span>• Web, game, aplication developer</span>
                    <span>• Responsible for project technical strategies</span>
                  </div>
                </div>

                <div className='teamBox'>
                  <div className='teamCover'>
                    <img src={`${process.env.PUBLIC_URL}/img/team/2.png`}></img>
                  </div>
                  <div className='teamName'><span className='diamond'></span> Mother Of Dragons</div>
                  <div className='teamPosition'>CEO</div>
                  <div className='teamDesc'>
                    <span>• 3D Artist</span>
                    <span>• Involved in the whole process of the game creation</span>
                    <span>• Put all creative ideas into practice</span>
                    <span>• Responsible for business-related things </span>
                  </div>
                </div>

                <div className='teamBox'>
                  <div className='teamCover'>
                    <img src={`${process.env.PUBLIC_URL}/img/team/3.png`}></img>
                  </div>
                  <div className='teamName'><span className='diamond'></span> Gon Gon</div>
                  <div className='teamPosition'>COMMUNITY MANAGER</div>
                  <div className='teamDesc'>
                    <span>• Digital engagement to build brand presence and trust</span>
                    <span>• Maintain productive partnerships</span>
                    <span>• Always searching for future strategies</span>
                    <span>• Responsible for growth and community development</span>
                  </div>
                </div>
        </div>

        <div className='moderatorsContent'>
        <div className='moderatorBox'>
            <div className='moderatorName'><span className='diamond'></span>CIPRIAN</div>
            <div className='moderatorRole'>3D <span>ARTIST</span></div>
        </div>
        <div className='moderatorBox'>
            <div className='moderatorName'><span className='diamond'></span>AZ</div>
            <div className='moderatorRole'>TOKENOMICS <span>ADVISOR</span></div>
        </div>
        <div className='moderatorBox'>
            <div className='moderatorName'><span className='diamond'></span>JASON</div>
            <div className='moderatorRole'>BLOCKCHAIN <span>DEVELOPER</span></div>
        </div>
      </div>
      </section>
    </div>
  );
};

export default Home;
