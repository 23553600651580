import * as React from 'react';

  import {
    useGetAccountInfo
  } from '@multiversx/sdk-dapp/hooks';
  import {
    useGetPendingTransactions
  } from '@multiversx/sdk-dapp/hooks/transactions';

  import {
    logout
  } from '@multiversx/sdk-dapp/utils';


import axios from 'axios';
import { getNfts } from 'apiRequests';
import {stringToHex,decimalToHex } from 'apiRequests/utils';
import { ReactComponent as ExternalLink } from '../../../assets/img/link.svg';
import {dragonsCollection,explorerAddress} from '../../../config';

const Actions = () =>
{
    const { hasPendingTransactions } = useGetPendingTransactions();
    const [, setTransactionSessionId] = React.useState<string | null>(null);
    const [accountPrizes, setAccountPrizes] = React.useState([]);

    const [password, setPassword] = React.useState();
    const [nfts, setNftsList] = React.useState([]);
    const [eggs, setEggsList] = React.useState([]);
    const [pending, setPending] = React.useState<boolean>(true);
    const [isEligible, setIsEligible] = React.useState<number>(1);
    const [arChances, setArChances] = React.useState<number>(1);

    const account = useGetAccountInfo();
    const { address } = account;
    const source = axios.CancelToken.source();
    const mounted = React.useRef(true);

const getDragons = () => {
      axios.get(getNfts(address), {cancelToken: source.token})
      .then((response) => {
        const expandedData = Object.values(response['data']['data']['esdts']) as any;
            const arr = [] as any;
            if (mounted.current)
            {
              for(let i = 0; i < expandedData.length; i++)
              {
                const collectionName = expandedData[i]['tokenIdentifier'];
                const collectionSplit = collectionName.split('-');
                const f = collectionSplit[0] +'-'+ collectionSplit[1];
                if(f === dragonsCollection)
                {
                  arr.push(expandedData[i]);
                }
              }
              setNftsList(arr);
              setPending(false);
            }
      })
      .catch(() => {
        if(mounted.current)
        {
          setPending(false);
        }
      });
  };

const handleLogout = () => {
  logout(`${window.location.origin}/connect`);
};

const showPassword = () => {
  axios.post('https://ar.dragonsarena.io/dappGeneratePassword.php', {wallet: address})
  .then((response) =>
  {
      const passwordResponse = Object.values(response) as any;
      setPassword(passwordResponse[0]);
  })
  .catch((error) =>
  {
      console.log(error);
  });
};
const regeneratePassword = () => {
  axios.post('https://ar.dragonsarena.io/dappRegeneratePassword.php', {wallet: address})
  .then((response) =>
  {
      const passwordResponse = Object.values(response) as any;
      setPassword(passwordResponse[0]);
  })
  .catch((error) =>
  {
      console.log(error);
  });
};

const fetchAccountRewards = () => {
  axios.get('https://ar.dragonsarena.io/v2/dapp/dappArHolderRewards.php?erd='+ address, {cancelToken: source.token})
  .then((response) => {
      const rewards = Object.values(response['data']['account']) as any;
      setAccountPrizes(rewards);
  })
  .catch((error) => {
      console.log(error);
  });
};

const fetchEligible = () => {
  axios.get('https://ar.dragonsarena.io/v2/dapp/dappAREligible.php?erd='+ address, {cancelToken: source.token})
  .then((response) => {
      const rewards = Object.values(response['data']['walletEligible']) as any;
      setIsEligible(rewards[0]);
      setArChances(rewards[1]);
  })
  .catch((error) => {
      console.log(error);
  });
};


React.useEffect(() => {
  fetchAccountRewards();
  fetchEligible();
  getDragons();
  return () => {
    mounted.current = false;
    source.cancel();
  };
}, [hasPendingTransactions]);

return(
  <section className='section'>
      <div className='itemContainer'>
              <div className='accountContainer'>
              <div className='accountLabel'>
                  <h2 className='fjalla'>MY ACCOUNT</h2>
                  <div id='pageLabel'></div>
              </div>
                  <div className='accountRow'>
                    <span className='accountRow-1'><div className='diamond'></div>YOUR WALLET:</span>
                    <span className='accountRow-2'>{address}</span>
                  </div>
                  <div className='accountRow'>
                    <span className='accountRow-1'><div className='diamond'></div>EGODS BALANCE:</span>
                    <span className='accountRow-2'>COMING SOON</span>
                  </div>
                  <div className='accountRow'>
                    <span className='accountRow-1'><div className='diamond'></div>AR CONNECT:</span>
                    <span className='accountRow-2'>{isEligible == 1 ? (<span className='arEligible'>YOU ARE ELIGIBLE FOR THE NEXT REWARDS</span>):(<span className='arNotEligible'>YOU ARE NOT ELIGIBLE FOR THE NEXT REWARDS</span>)}</span>
                  </div>
                  <div className='accountRow'>
                    <span className='accountRow-1'><div className='diamond'></div>AR CHANCES:</span>
                    <span className='accountRow-2'>{ arChances > 0 ? (<span><span className='arChances'>+{arChances}</span> CHANCES</span>):(<span><span className='arChances'>NORMAL</span> CHANCES</span>)}</span>
                  </div>
                  <div className='accountRow'>
                  <button className='btnBorder' onClick={handleLogout}>DISCONNECT</button>
                  </div>
              </div>
      </div>
      <div className='arPasswordContainer'>
      <h2 className='fjalla'>AR CONNECT</h2>
      <div id='pageLabel'></div>
            <div className='arGeneratePasswordLabel'>
                {
                    password ?
                    (
                        <div className='passwordReveal'>
                            <div className='yourPass fjalla'>{password}</div> 
                            <button className='generateAgain' onClick={() => regeneratePassword()}> <img src={`${process.env.PUBLIC_URL}/img/redo.png`}/></button>
                        </div>
                    )
                    :
                    (
                        <button className='generatePasswordBtn' onClick={() => showPassword()}>PASSWORD</button>
                    )
                }
            </div>
            <div className='arDisclaimer fjalla'>
                The password is unique to your wallet address! Keep it safe!
            </div>
      </div>

      <div className='section sectionARConnect'>
      <div className='rewardsContainer'>
        <div className='rewardsContainerOverflow'>
                    <div className='rewardsLegend'>
                        <div className = 'rewardColumn rewardId rewardLegendColumn'>
                                ID
                        </div>
                        <div className = 'rewardColumn rewardDragonId rewardLegendColumn'>
                                DRAGON ID
                        </div>
                        <div className = 'rewardColumn rewardType rewardLegendColumn'>
                                TYPE
                        </div>
                        <div className = 'rewardColumn rewardPrizeName rewardLegendColumn'>
                                PRIZE
                        </div>
                        <div className = 'rewardColumn rewardQuantity rewardLegendColumn'>
                                QUANTITY
                        </div>
                        <div className = 'rewardColumn startDate rewardLegendColumn'>
                               DATE
                        </div>
                        <div className = 'rewardColumn rewardStatus rewardLegendColumn'>
                               STATUS
                        </div>
                    </div> 
       {
                accountPrizes.map((reward: any, index: number) =>
                {

                        return(
                            <div key={index}>
                            {
                                 <div>
                                 <div className='rewardsRow'>
                                     <div className = 'rewardColumn rewardType'>
                                         {
                                             (() => {
                                                if (reward.dragontype == 'Epic')
                                                {
                                                    return(
                                                        <div className='epicTypePrize'>EPIC</div>
                                                     );
                                                }
                                                else if (reward.dragontype == 'Legendary')
                                                {
                                                    return(
                                                        <div className='legendaryTypePrize'>LEGENDARY</div>
                                                     );
                                                }
                                                else
                                                {
                                                    return(
                                                        <div className='commonTypePrize'>COMMON</div>
                                                     );                 
                                                }
                                             })()
    
                                        }
                                     </div>
                                     <div className = 'rewardColumn rewardPrizeName'>
                                             {reward.prizeName}
                                     </div>
                                     <div className = 'rewardColumn rewardQuantity'>
                                             {reward.prize}
                                     </div>
                                     <div className = 'rewardColumn startDate'>
                                             <span className='date'>{reward.submitDate}</span>
                                     </div>
                                     <div className='rewardColumn rewardStatus'>
                                     {
                                         reward.claimed == 1 ?
                                         (
                                          <div className='rewardClaimed'>
                                            CLAIMED
                                          </div>
                                         )
                                         :
                                         (
                                            <div className='rewardUnClaimed'>
                                                NOT CLAIMED
                                            </div>
                                          )
                                     }
                                     </div>
                                 </div>
                                </div>
                            }
                             </div>
                         );
                    
                }
            )
       }
                {accountPrizes.length === 0 &&
                <div>
                  <div className='nonfts fjalla'>
                    You dont have any rewards!
                    <img src={`${process.env.PUBLIC_URL}/img/sad.png`}/>
                  </div>
                </div>
              }

    </div>
    </div>
</div>

      <div className='itemContainer'>
        <div className='accountLabel'>
            <h2 className='fjalla'>MY DRAGONS</h2>
            <div id='pageLabel'></div>
        </div>
              {pending ? 
              (
              <div className='loadingBox'>
                  <img id='loading' src={`${process.env.PUBLIC_URL}/img/spinner.gif`}/>
              </div>
              ) 
              :
              (
              nfts.map((nft: any, index: number) => (
                  <div key={index}>
              {nft.uris?.[0] && 
                (
                  <div className='itemBox'>
                      <a href={atob(nft.uris[0])} target='_blank' rel='noreferrer'>
                        <img className='item' src={'https://dragonsarena.io/img/small/' + nft.name.split('#')[1] + '.png'}/>
                      </a>
                      <span>{nft.name}</span>
                      <a href={`${explorerAddress}nfts/${nft.tokenIdentifier}`} className='transactionLink' target='_blank' rel='noreferrer'>
                        Transaction <ExternalLink className='externalLink'/>
                      </a>
                  </div>
                )}
                      {nft.uri}
                  </div>
              ))
              )
              }
              {!pending && nfts.length === 0 && 
              <div className='nonfts fjalla'>
                You dont have any Dragons Arena NFT
                <img src={`${process.env.PUBLIC_URL}/img/sad.png`}/>
              </div>
              }
          </div>
          <div className='itemContainer'>
          <div className='accountLabel'>
            <h2 className='fjalla'>MY DRAGON EGGS</h2>
            <div id='pageLabel'></div>
          </div>
                {pending ? 
                (
                <div className='loadingBox'>
                    <img id='loading' src={`${process.env.PUBLIC_URL}/img/spinner.gif`}/>
                </div>
                ) 
                :
                (
                eggs.map((nft: any, index: number) => (
                    <div key={index}>
                {nft.uris?.[0] && 
                  (
                    <div className='itemBox'>
                        <a href={atob(nft.uris[0])} target='_blank' rel='noreferrer'><img className='item' src={atob(nft.uris[0])}/></a>
                        <span>#{nft.tokenIdentifier}</span>
                        <a href={`${explorerAddress}nfts/${nft.tokenIdentifier}`} className='transactionLink' target='_blank' rel='noreferrer'>Transaction <ExternalLink className='externalLink'/></a>
                    </div>
                  )}
                        {nft.uri}
                    </div>
                ))
                )
                }
                {!pending && eggs.length === 0 && 
                  <div className='nonfts fjalla'>
                  You dont have any Dragons Arena Eggs
                  <img src={`${process.env.PUBLIC_URL}/img/sad.png`}/>
                </div>
                }
            </div>
  </section>
);
};

export default Actions;
