import React from 'react';
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton
} from '@multiversx/sdk-dapp/UI';
import {
  useGetLoginInfo,
} from '@multiversx/sdk-dapp/hooks/account';
import { routeNames } from 'routes';

export const UnlockRoute: () => JSX.Element = () => {

  const { isLoggedIn } = useGetLoginInfo();

  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.home;
    }
  }, [isLoggedIn]);
  const commonProps = {
    callbackRoute: routeNames.home,
    nativeAuth: true
  };
  const projectid = 'c4343c545c1c210c2cdc6b7d31ade518';
  return (
    <div className='home d-flex flex-fill align-items-center'>
      <div className='m-auto' data-testid='unlockPage'>
        <div className='card my-4 text-center'>
          <div className='card-body py-4 px-2 px-sm-2 mx-lg-4'>
            <h4 className='mb-4'>Login</h4>
            <p className='mb-4'>Pick a login method</p>
            <ExtensionLoginButton
              loginButtonText='MultiversX DeFi Wallet'
              {...commonProps}
            />

            <WebWalletLoginButton
              loginButtonText='MultiversX Web Wallet'
              {...commonProps}
            />
            <LedgerLoginButton
              loginButtonText='Ledger'
              className='test-class_name'
              {...commonProps}
            />
            <WalletConnectLoginButton
              loginButtonText='xPortal App'
              {...commonProps}
              {...(projectid
                ? {
                    isWalletConnectV2: true
                  }
                : {})}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockRoute;